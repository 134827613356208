<template>
  <demographics-template
    :date-of-birth="dateOfBirth"
    :gender="gender"
    :genotype="genotype"
    :genotype-visible="genotypeVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import differenceInYears from 'date-fns/difference_in_years';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder, createValidator, VALIDATOR } from '@/modules/questionnaire/mixins/makeStep';

import DemographicsTemplate from '@/modules/questionnaire/components/steps/common/identity/demographics/DemographicsTemplate';

import { other } from '@/modules/questionnaire/api/helpers';

import rootTypes from '@/store/types';
import { types } from '@/modules/questionnaire/store/types';

const { field, requiredField, fieldWithCustomValidation } = fieldBuilder;

const validateAge = value => {
  const difference = differenceInYears(new Date(), value);

  return difference >= 12 && difference <= 120;
};
const validateDate = dateString =>
  dateString.length === 'yyyy-mm-dd'.length && !isNaN(new Date(dateString).getTime());

const dateOfBirthValidator = createValidator(validateAge, 'dashboard.warning.ageBetween');
const dateValidator = createValidator(validateDate, 'Should be valid date');

const FIELDS = [
  field('age'),
  fieldWithCustomValidation('dateOfBirth', [
    VALIDATOR.REQUIRED,
    dateValidator,
    dateOfBirthValidator
  ]),
  requiredField('gender'),
  requiredField('genotype')
];

export default {
  name: 'Demographics',
  components: {
    DemographicsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    ...mapGetters({
      questionnaireLocale: rootTypes.getters.GET_LOCALE,
      patient: types.getters.GET_PATIENT
    }),
    genotypeVisible() {
      return other(this.gender);
    }
  },
  watch: {
    dateOfBirth(newValue) {
      if (newValue) {
        this.age = Math.floor(differenceInYears(new Date(), newValue));

        this.scrollTo('#gender');
      }
    },
    gender(newValue, oldValue) {
      if (!oldValue && !other(newValue)) {
        this.showNextStep();
      } else if (other(newValue)) {
        this.scrollTo('#genotype');
      }
    },
    genotype(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  async created() {
    await this.fetchPatient({ email: this.patient.email });

    const { locale } = this.patient;

    if (this.questionnaireLocale !== locale) {
      await this.updatePatient({ locale: this.questionnaireLocale });
    }
  },
  methods: {
    ...mapActions({
      fetchPatient: types.actions.FETCH_PATIENT,
      updatePatient: types.actions.UPDATE_PATIENT
    }),
    isFieldVisible(fieldName) {
      if (fieldName === 'genotype') {
        return this.genotypeVisible;
      }

      return true;
    }
  }
};
</script>
